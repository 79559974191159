import 'angular:locale/data:zh';
import 'angular:locale/data:tr';
import 'angular:locale/data:th';
import 'angular:locale/data:ru';
import 'angular:locale/data:pt';
import 'angular:locale/data:id';
import 'angular:locale/data:fr';
import 'angular:locale/data:fa';
import 'angular:locale/data:es';
import 'angular:locale/data:en';
import 'zone.js';(globalThis.$localize ??= {}).locale = "___NG_LOCALE_INSERT___";
